<template>
  <loader v-if="pending" center />
  <div v-else :class="$style.container">
    <div :class="$style.title" :style="headerStyles">{{ $t("Breed.title") }}</div>
    <div :class="$style.rooms">
      <div :class="$style.list">
        <div
          v-for="room in rooms"
          :id="room.number"
          :key="room.number"
          :class="$style.room"
          @click="changeRoom(room.number)"
        >
          <div v-if="room.name.length > 0" :class="$style.room__number" :title="room.name">
            {{ room.name }}
          </div>
          <div>
            <p :class="$style.room__description">{{ room.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <pin-request
      v-if="requestRoomNumber"
      :visible="pinRequestVisible"
      :chosen-room="chosenRoom"
      @room-change="changeRoom"
      @close="closeHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { broadcast, room } from "@/store/modules/store.namespaces";
import { CHANGE_ROOM } from "@/store/modules/common/action-types";
import { IS_CONFERENCE_TYPE } from "@/store/modules/broadcast/getter-types";
import { DELETE_LEADING } from "@/store/modules/broadcast/action-types";

import PinRequest from "@/components/event/PinRequest";
import Loader from "../../common/elements/Loader";

export default {
  name: "Breed",
  components: { Loader, PinRequest },
  data() {
    return {
      pending: true,
      chosenRoom: "",
      pinRequestVisible: false,
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
      distributingRooms: state => state.roomInfo.distributingRooms,
      requestRoomNumber: state => state.roomInfo.request_room_number,
      themeStyleColors: state => state.styleColors,
    }),
    ...mapGetters(broadcast, {
      isConferenceType: IS_CONFERENCE_TYPE,
    }),
    rooms() {
      return this.distributingRooms
        .filter(room => room.number !== this.room)
        .map(room => ({
          ...room,
          name: this.nameRoom(room),
          description: this.descRoom(room.name),
        }));
    },
    headerStyles() {
      if (this.themeStyleColors.innerHeadersColor) {
        return `color: ${this.themeStyleColors.innerHeadersColor}`
      }
    },
  },
  mounted() {
    this.pending = false;
  },
  methods: {
    ...mapActions(room, {
      changeRoomAction: CHANGE_ROOM,
    }),
    ...mapActions(broadcast, {
      deleteLeading: DELETE_LEADING,
    }),
    async changeRoom(roomNumber, redirect = false) {
      if (this.requestRoomNumber && !redirect) {
        this.pinRequestVisible = true;
        this.chosenRoom = roomNumber;
      } else {
        this.pending = true;
        try {
          if (this.isConferenceType) {
            await this.deleteLeading();
          }
          await this.changeRoomAction(roomNumber);
        } finally {
          this.pending = false;
        }
      }
    },
    closeHandler() {
      this.pinRequestVisible = false;
      this.chosenRoom = "";
    },
    nameRoom(room) {
      let { name } = room;
      name = `${name}`.trim();
      if (name.charAt(0) === "!" && name.charAt(1) === "#") {
        return "";
      }
      if (name.indexOf("#") > 1) {
        return name.split("#")[0];
      }
      return `#${room.number}`;
    },
    descRoom(str) {
      if (str.indexOf("#") !== -1) {
        return str.split("#")[1];
      }
      return str;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  padding: 20px 5px;
  margin: 0 auto;
}

.title {
  padding-top: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #1e1e1e;
  text-align: center;
}

.rooms {
  margin: auto auto 20px;
  text-align: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 12px;
  max-width: $tablet-l;
  padding: 0 15px;
  margin: auto;
  text-align: center;

  @include mq-tablet {
    padding: 0;
  }
}

.room {
  padding: 18px;
  text-align: center;
  cursor: pointer;
  border: solid 1px #e0e0e0;
  border-radius: 8px;
  background-color: white;

  &:hover {
    color: var(--base-button-active-color);
    background-color: var(--base-color);
    border: solid 1px var(--base-color);
    box-shadow: 0 12px 32px 0 rgba(140, 80, 0, 0.49);
  }

  &__number {
    width: 100%;
    margin-bottom: 18px;
    overflow: hidden;
    font-size: 52px;
    font-weight: 300;
    line-height: 0.8em;
    text-align: left;
    word-break: break-word;
  }

  &__description {
    width: 100%;
    font-size: 21px;
    text-align: justify;
    word-break: break-word;
  }
}
</style>
