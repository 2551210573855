<template>
  <smart-modal :visible="visible" :class="$style.modal" @close="close">
    <template #full>
      <div class="flex flex-column">
        <ws-button icon :class="$style.close" color="default" @click="close">
          <ws-icon light xxl color="default">times-circle</ws-icon>
        </ws-button>
        <div :class="$style.label">
          <span :class="$style.title">{{ $t("Pin.title") }}</span>
          <p v-show="error" :class="$style.error">{{ $t("Pin.error") }}</p>
        </div>
        <form :class="$style.form" @submit.prevent="submitPinRequest">
          <div :class="$style.form__line">
            <input
              v-model.number="roomInput"
              type="text"
              :class="$style.form__input"
              inputmode="numeric"
            />
          </div>
          <ws-button :class="$style.enter" type="submit" color="primary" lg font-size="xl">
            {{ $t("Login.login") }}
          </ws-button>
        </form>
      </div>
    </template>
  </smart-modal>
</template>

<script>
import SmartModal from "@/components/common/modals/SmartModal";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";

export default {
  components: {
    SmartModal,
    WsIcon,
    WsButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    chosenRoom: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      roomInput: "",
      error: false,
    };
  },
  computed: {
    room() {
      return +this.$route.params.id;
    },
  },
  methods: {
    close() {
      this.roomInput = "";
      this.error = false;
      this.$emit("close");
    },
    submitPinRequest() {
      if (this.roomInput === +this.chosenRoom) {
        this.$emit("room-change", this.chosenRoom, true);
        this.close();
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss" module>
.close {
  margin-left: auto;
}

.label {
  margin-top: 18px;
  text-align: center;
}

.title {
  font-size: 18px;

  @include mq-tablet {
    font-size: 22px;
  }
}

.error {
  color: $danger;
}

.form {
  margin-top: 20px;

  &__line {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__input {
    width: 100%;
    padding: 10px 2px;
    font-size: 16px;
    color: inherit;
    text-align: center;
    background: none;
    border: none;
    border-bottom: 1px solid $gray;
  }

  &__input:focus {
    border-bottom: 1px solid var(--base-text-color);
    outline: none;
  }
}

.enter {
  margin-top: 20px;
}

.modal > [class*="smart-modal__panel"] {
  width: 100% !important;
  max-width: 300px !important;
  padding-top: 20px !important;

  @include mq-tablet {
    max-width: 320px !important;
  }
}
</style>
